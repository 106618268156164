import { createContext, useState } from "react";
const QuestionnaireContext = createContext({});

export const QuestionnaireDataProvider = ({ children }) => {
  //Common Values
  const [isQuestionnaireShow, setIsQuestionnaireShow] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(false);

  const handleViewQuestionInfo = (question) =>{
    setIsQuestionnaireShow(true)
    setSelectedQuestion(question)
  }

  /* Draft Questionnaire */
  const [initDraftQuestionnaireHomePage, setInitDraftQuestionnaireHomePage] = useState(true);  
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  const [initDraftQuestionnairePage, setInitDraftQuestionnairePage] = useState(true);  
  const [draftQuestionnaireList, setDraftQuestionnaireList] = useState([]);

  const [draftQuestionnaireListCount, setDraftQuestionnaireListCount] = useState(0);

  const [draftQuestionnaireListParam, setDraftQuestionnaireListaram] = useState({
    assessment_id: 1,
    limit: 30,
    sort_field: "value",
    order_by:"value",
    order: "ASC",
    offset: 0,
    search:"",
    is_approved: null
  });
  

  return (
    <QuestionnaireContext.Provider
      value={{
        isQuestionnaireShow,
        setIsQuestionnaireShow,
        handleViewQuestionInfo,
        selectedQuestion,
        setSelectedQuestion,
        
        initDraftQuestionnairePage,
        setInitDraftQuestionnairePage,
        draftQuestionnaireList, setDraftQuestionnaireList,
        draftQuestionnaireListCount, setDraftQuestionnaireListCount,
        draftQuestionnaireListParam, setDraftQuestionnaireListaram,
        initDraftQuestionnaireHomePage, 
        setInitDraftQuestionnaireHomePage,
        assessmentQuestions, setAssessmentQuestions
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

export default QuestionnaireContext;
