import React from "react";
import {
  faBell,
  faBowlFood,
  faBowlRice,
  faComments,
  faCubesStacked,
  faUser,
  faHomeAlt,
  faFingerprint,
  faUserShield,
  faUserTag,
  faUsers,
  faWheatAlt,
  faDiagramProject,
  faWheatAwnCircleExclamation,
  faWheatAwn,
  faStore,
  faCircleDot,
  faEnvelope,
  faBookMedical,
  faCode,
  faFilePdf,
  faGears,
  faUsersGear,
  faQuestion,
  faClipboardQuestion,
  faClock
} from "@fortawesome/free-solid-svg-icons";

const Home = React.lazy(() => import("../components/home/Home"));
const UserVerification = React.lazy(() => import("../components/human-token/token-verification/UserVerification"));
const PremiumUsers = React.lazy(() => import("../components/human-token/premium-users/PremiumUsers"));
const CGMPurchase = React.lazy(() => import("../components/human-token/purchase-cgm/CGMPurchase"));
const HumanTokenList = React.lazy(() => import("../components/human-token/claim-token/HumanTokenList"));
const WaitlistUsers = React.lazy(() => import("../components/user-waitlist/UserWaitlist"));
const ManualNotificationsView = React.lazy(() => import("../components/notifications/ManualNotificationsView"));
const Notifications = React.lazy(() => import("../components/notifications/Notifications"));
const EmailViewer = React.lazy(() => import("../components/email-template/EmailViewer"));
const WhatsappQuery = React.lazy(() => import("../components/whatsapp-query/WhatsappQuery"));
const QuestionnaireFlow = React.lazy(() => import("../components/question-flow/QuestionnaireFlow"));
const Food = React.lazy(() => import("../components/nutrition/food/Food"));
const Meal = React.lazy(() => import("../components/nutrition/meal/Meal"));
const UnclassifiedFood = React.lazy(() => import("../components/nutrition/meal-tracker/UnclassifiedFood"));
const MealTracker = React.lazy(() => import("../components/nutrition/meal-tracker/MealTracker"));
const ManualOperations = React.lazy(() => import("../components/manual-operations/ManualOperations"));
const BookComponent = React.lazy(() => import("../components/pdf-generate/BookComponent"));
const Profile = React.lazy(() => import("../components/profile/Profile"));
const ManageUsers = React.lazy(() => import("../components/manage-users/ManageUsers"));
const RolePermissions = React.lazy(() => import("../components/roles/RolePermissions"));

const QuestionnaireHome = React.lazy(() => import("../components/questionnaire/QuestionnaireHome"));
const QuestionnaireHomeNew = React.lazy(() => import("../components/questionnaire/QuestionnaireHomeNew"));
const QuestionnaireList = React.lazy(() => import("../components/questionnaire/QuestionnaireList"));


export const RoutesList = [
  {
    label: "Home",
    heading_name: "Dashboard",
    key: "",
    link: "/",
    icon: faHomeAlt,
    element: Home,
    permissions: "all",
  },
  {
    label: "Token User",
    heading_name: "Human Token",
    key: "token-user",
    icon: faUserTag,
    subMenu: [
      {
        label: "User Verification",
        key: "user-verify",
        link: "/user-verify",
        icon: faUsers,
        element: UserVerification,
        permissions: [2, 4],
      },
      {
        label: "Paid Users",
        key: "paid-users",
        link: "/paid-users",
        icon: faUserShield,
        element: PremiumUsers,
        permissions: [2, 4],
      },
      {
        label: "CGM Purchase",
        key: "cgm-purchase",
        link: "/cgm-purchase",
        icon: faStore,
        element: CGMPurchase,
        permissions: [2, 4],
      },
      {
        label: "Human Token Users",
        key: "human-token",
        link: "/human-token",
        icon: faBookMedical,
        element: HumanTokenList,
        permissions: [2, 4, 5],
      },
    ],
  },
  {
    label: "User Waitlist",
    key: "waitlist",
    link: "/waitlist",
    icon: faClock,
    element: WaitlistUsers,
    permissions: [2, 4],
  },
  {
    label: "Notifications",
    heading_name: "Notifications",
    key: "manual-notifications",
    icon: faBell,
    subMenu: [
      {
        label: "Manual Notifications",
        key: "manual-notifications",
        link: "/manual-notifications",
        icon: faCircleDot,
        element: ManualNotificationsView,
        permissions: [2, 4],
      },
      {
        label: "App Notifications",
        key: "notifications",
        link: "/notifications",
        icon: faBell,
        element: Notifications,
        permissions: [2, 4],
      },
    ],
  },
  {
    label: "Email Template",
    key: "email-viewer",
    link: "/email-viewer",
    icon: faEnvelope,
    element: EmailViewer,
    permissions: [6],
  },
  {
    label: "Whatsapp Queries",
    key: "queries",
    link: "/queries",
    icon: faComments,
    element: WhatsappQuery,
    permissions: [2, 4],
  },
  {
    label: "Questionnaire",
    heading_name: "Questionnaire",
    key: "questionnaire-home",
    icon: faClipboardQuestion,    
    subMenu: [
      {
        label: "Questionnaire",
        key: "questionnaire-home",
        link: "/questionnaire-home",
        icon: faQuestion,
        element: QuestionnaireHome,
        permissions: [2, 5],
      },
      {
        label: "Table View",
        key: "questionnaire-table-view",
        link: "/questionnaire-table-view",
        icon: faQuestion,
        element: QuestionnaireList,
        permissions: [2, 5],
      },
      {
        label: "Question Visual",
        key: "questionnaire",
        link: "/questionnaire",
        icon: faDiagramProject,
        element: QuestionnaireFlow,
        permissions: [2, 5],
      }
    ],
  },  
  {
    label: "Nutrition",
    heading_name: "Nutrition",
    key: "nutrition",
    icon: faBowlFood,
    subMenu: [
      {
        label: "Food",
        key: "food",
        link: "/food",
        icon: faWheatAlt,
        element: Food,
        permissions: [2, 5],
      },
      {
        label: "Meal",
        key: "meal",
        link: "/meal",
        icon: faBowlRice,
        element: Meal,
        permissions: [2, 5],
      },
    ],
  },
  {
    label: "Food Intake Tracker",
    key: "food-intake",
    icon: faCubesStacked,
    subMenu: [
      {
        label: "Unclassified food",
        key: "unclassified-food",
        link: "/unclassified-food",
        icon: faWheatAwnCircleExclamation,
        element: UnclassifiedFood,
        permissions: [2, 5],
      },
      {
        label: "Data verified food",
        key: "verified-food",
        link: "/verified-food",
        icon: faWheatAwn,
        element: MealTracker,
        permissions: [2, 5],
      },
    ],
  },
  {
    label: "Developer Options",
    heading_name: "Developer Options",
    key: "developer-options",
    icon: faBell,
    subMenu: [
      {
        label: "Developer Options",
        key: "developer-options",
        link: "/developer-options",
        icon: faCode,
        element: ManualOperations,
        permissions: [6],
      },
    ],
  },
  {
    label: "Book Automation",
    key: "book-automation",
    heading_name: "book-automation",
    link: "/book-automation",
    icon: faFilePdf,
    element: BookComponent,
    permissions: [2],
    // is_display_none: true,
  },
  {
    label: "Profile",
    key: "profile",
    heading_name: "Profile",
    link: "/profile",
    icon: faUser,
    element: Profile,
    permissions: "all",
    is_display_none: true,
  },
  {
    label: "Admin",
    heading_name: "Settings",
    key: "admin",
    icon: faGears,
    subMenu: [
      {
        label: "Roles & Permissions",
        key: "role-permisions",
        link: "/role-permisions",
        icon: faFingerprint,
        element: RolePermissions,
        permissions: [2],
      },
      {
        label: "Manage Users",
        key: "manage-users",
        link: "/manage-users",
        icon: faUsersGear,
        element: ManageUsers,
        permissions: [2],
      },
    ],
  },
];
