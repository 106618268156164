import { createContext, useState } from "react";
// import { getDiOrderData } from "../utils/preTokenApi";
// import MainContext from "./MainContext";

const TokenContext = createContext({});

export const TokenDataProvider = ({ children }) => {
  
  //Common Values
  const [kitOrderData, setKitOrderData] = useState([]);
  const [kitListTotalCount, setKitListTotalCount] = useState([]);

  const [pickupOrderData, setPickupOrderData] = useState([]);

  // const { setIsPreLoading } = useContext(MainContext);

  /* Order API Params */
  const [diOrderParam, setDiOrderParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: ""
  });

  /* Kit API Params */
  const [diKitParam, setDiKitParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: ""
  });


  /* Pickup order DI-Order List */
  const [initRenderDIOrder, setInitRenderDIOrder] = useState(true);  
  const [dIOrderList, setDIOrderList] = useState([]);

  /* order DI-Kit List */
  const [initRenderDIKit, setInitRenderDIKit] = useState(true);  
  const [dIKitList, setDIKitList] = useState([]);

  /* Verification User List */
  const [initRenderVerifiy, setInitRenderVerifiy] = useState(true);  
  const [userVerificationList, setUserVerificationList] = useState([]);
  const [userVerificationListTotalCount, setUserVerificationListTotalCount] = useState();

  const [verificationListParam, setVerificationListParam] = useState({
    limit: 25,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: "",
    verification_id:"",
    is_paid: "",
    is_verified: "",
  });

  /* Permium User List */
  const [initRenderPremium, setInitRenderPremium] = useState(true);  
  const [premiumUserList, setPremiumUserList] = useState([]);

  const [premiumUserListParam, setPremiumUserListParam] = useState({
    limit: 25,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: ""
  });

  const [initRenderCGMAction, setInitRenderCGMAction] = useState(true);  
  const [cgmUserActionList, setCgmUserActionList] = useState([]);
  const [cgmUserActionListCount, setCgmUserActionListCount] = useState("");

  const [cgmUserActionParam, setCgmUserActionParam] = useState({
    limit: 10,
    orderBy: "id",
    order: "ASC",
    offset: 0,
    user_action: "cst_purchase,cst_continue",
    is_suggestion_met: null,
    is_notification_send: null,
    is_expired: null
  });

  /*  */

  const [initRenderHumanTokenUsers, setInitRenderHumanTokenUsers] = useState(true);  
  const [humanTokenUsersList, setHumanTokenUsersList] = useState([]);
  const [humanTokenUsersLevelProgress, setHumanTokenUsersLevelProgress] = useState([]);
  const [humanTokenUsersLevelProgressFull, setHumanTokenUsersLevelProgressFull] = useState([]);

  const [humanTokenUsersListCount, setHumanTokenUsersListCount] = useState(0);

  const [humanTokenUsersListParam, setHumanTokenUsersListParam] = useState({
    limit: 250,
    sort_field: "user_id",
    order: "ASC",
    offset: 0,
    search:""
  });

  /* Waitlist User List */
  const [initRenderWaitlist, setInitRenderWaitlist] = useState(true);  
  const [waitlistUserList, setWaitlistUserList] = useState([]);

  const [waitlistUserListParam, setWaitlistUserListParam] = useState({
    limit: 25,
    offset: 0,
    orderBy: "name",
    order: "ASC",
    search: ""
  });
  
  return (
    <TokenContext.Provider
      value={{

        kitOrderData,
        kitListTotalCount, 
        setKitListTotalCount,
        setKitOrderData,

        pickupOrderData,
        setPickupOrderData,

        /* HT_User_verification */
        initRenderVerifiy,
        setInitRenderVerifiy,
        verificationListParam, setVerificationListParam,
        userVerificationList, setUserVerificationList,
        userVerificationListTotalCount, setUserVerificationListTotalCount,

        /* Premium User */

        initRenderPremium, setInitRenderPremium,
        premiumUserList, setPremiumUserList,
        premiumUserListParam, setPremiumUserListParam,
        
        /* Di Order */
        initRenderDIOrder, setInitRenderDIOrder,
        diOrderParam, setDiOrderParam,
        dIOrderList, setDIOrderList,

        /* Di Kit */
        initRenderDIKit, setInitRenderDIKit,
        diKitParam, setDiKitParam,
        dIKitList, setDIKitList,

        /* CGM Expiry */
        initRenderCGMAction, setInitRenderCGMAction,
        cgmUserActionList, setCgmUserActionList,
        cgmUserActionParam, setCgmUserActionParam,
        cgmUserActionListCount, setCgmUserActionListCount,

        /* Human Token Users */
        initRenderHumanTokenUsers, setInitRenderHumanTokenUsers,
        humanTokenUsersList, setHumanTokenUsersList,
        humanTokenUsersLevelProgress, setHumanTokenUsersLevelProgress,
        humanTokenUsersLevelProgressFull, setHumanTokenUsersLevelProgressFull,

        humanTokenUsersListCount, setHumanTokenUsersListCount,
        humanTokenUsersListParam, setHumanTokenUsersListParam,

        /* Waitlist User */
        
        initRenderWaitlist, setInitRenderWaitlist,
        waitlistUserList, setWaitlistUserList,
        waitlistUserListParam, setWaitlistUserListParam,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenContext;